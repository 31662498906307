
import { Options, Vue } from "vue-class-component";
import mapKeys from "lodash/mapKeys";
import pickBy from "lodash/pickBy";
import pick from "lodash/pick";
import { saveAs } from "file-saver";
import { unparse } from "papaparse";

export const isType = (value: any, type: any) => typeof value === type;
// reference https://github.com/Belphemur/vue-json-csv/blob/develop/src/JsonCSV.vue
@Options({
  emits: ["export-started", "export-finished"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    jsonData: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: false
    },
    outputFileName: {
      type: String,
      default: "data.csv",
      required: false
    },
    /**
     * Delimiter for the CSV file
     */
    delimiter: {
      type: String,
      default: ",",
      required: false
    },
    /**
     * Should the module add SEP={delimiter}
     *
     * Useful for opening file with Excel
     */
    separatorExcel: {
      type: Boolean,
      default: false
    },
    /**
     * What will be the encoding of the file
     */
    encoding: {
      type: String,
      default: "utf-8"
    },
    /**
     * Advanced options for Papaparse that is used to export to CSV
     */
    advancedOptions: {
      type: Object,
      default: () => {
        return {};
      }
    },
    /**
     * Labels for columns
     *
     * Object or function
     */
    labels: {
      required: false
    },
    /**
     * Used only for testing purposes
     */
    testing: {
      required: false,
      default: false
    }
  }
})
export default class GenerateCsv extends Vue {
  props: any = this.$props;
  // unique identifier
  get idName() {
    const now = new Date().getTime();
    return "export_" + now;
  }
  get exportableData() {
    const filteredData = this.cleaningData();
    if (!filteredData?.length) {
      return null;
    }
    return filteredData;
  }
  labelsFunctionGenerator() {
    if (
      !isType(this.props.labels, "undefined") &&
      !isType(this.props.labels, "function") &&
      !isType(this.props.labels, "object")
    ) {
      throw new Error("Labels needs to be a function(value,key) or object.");
    }
    if (isType(this.props.labels, "function")) {
      return (item: any) => {
        return mapKeys(item, this.props.labels);
      };
    }
    if (isType(this.props.labels, "object")) {
      return (item: any) => {
        return mapKeys(item, (_, key) => {
          return this.props.labels[key] || key;
        });
      };
    }
    return (item: any) => item;
  }
  fieldsFunctionGenerator() {
    if (
      !isType(this.props.fields, "undefined") &&
      !isType(this.props.fields, "function") &&
      !isType(this.props.fields, "object") &&
      !Array.isArray(this.props.fields)
    ) {
      throw new Error("Fields needs to be a function(value,key) or array.");
    }
    if (
      isType(this.props.fields, "function") ||
      (isType(this.props.fields, "object") && !Array.isArray(this.props.fields))
    ) {
      return (item: any) => {
        return pickBy(item, this.props.fields);
      };
    }
    if (Array.isArray(this.props.fields)) {
      return (item: any) => {
        return pick(item, this.props.fields);
      };
    }
    return (item: any) => item;
  }

  cleaningData() {
    if (
      isType(this.props.fields, "undefined") &&
      isType(this.props.labels, "undefined")
    ) {
      return this.props.jsonData;
    }
    const labels = this.labelsFunctionGenerator();
    const fields = this.fieldsFunctionGenerator();
    return this.props.jsonData.map((item: any) => labels(fields(item)));
  }
  generateCsv() {
    this.$emit("export-started");
    const dataExport = this.exportableData;
    if (!dataExport) {
      console.error("No data to export");
      return;
    }
    let csv = unparse(
      dataExport,
      Object.assign(
        {
          delimiter: this.props.delimiter,
          encoding: this.props.encoding
        },
        this.props.advancedOptions
      )
    );
    if (this.props.separatorExcel) {
      csv = "SEP=" + this.props.delimiter + "\r\n" + csv;
    }
    //Add BOM when UTF-8
    if (this.props.encoding === "utf-8") {
      csv = "\ufeff" + csv;
    }
    this.$emit("export-finished");
    if (!this.props.testing) {
      const blob = new Blob([csv], {
        type: "application/csvcharset=" + this.props.encoding
      });
      saveAs(blob, this.props.outputFileName);
    }
  }
}
