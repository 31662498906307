
import { Vue, Options } from "vue-class-component";
import { ShortlandController } from "@/app/ui/controllers/ShortlandController";
import GenerateCsv from "@/app/ui/components/json-to-csv/index.vue";
import {
  addPrefixGenesis,
  dateToDateString,
  ellipsisString,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { ShortlandData } from "@/domain/entities/Shortland";
import Notification from "@/app/ui/components/notification/index.vue";

@Options({
  components: {
    GenerateCsv,
    EmptyState,
    Notification
  },
  computed: {
    getTitle() {
      return "Shortland";
    }
  }
})
export default class StationTransitInDest extends Vue {
  mounted() {
    this.refs = this.$refs;
  }
  unmounted() {
    ShortlandController.setSearch("");
    ShortlandController.setStartDate("");
    ShortlandController.setEndDate("");
    ShortlandController.setResetShortlandListData();
  }

  refs: any = "";

  fetchShortlandList() {
    ShortlandController.fetchShortlandList();
  }

  // loading
  get isLoading() {
    return ShortlandController.isLoading;
  }

  // error
  get errorCause() {
    return ShortlandController.errorCause;
  }
  get isError() {
    return ShortlandController.isError;
  }
  handleErrorClose() {
    ShortlandController.setError(false);
    ShortlandController.setErrorCause("");
  }

  // filter date
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      ShortlandController.setStartDate(null);
      ShortlandController.setEndDate(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 13)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 13)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
      ShortlandController.setStartDate(this.tempStartDate);
      ShortlandController.setEndDate(this.tempEndDate);
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }

  get startDate() {
    return ShortlandController.filter.startDate;
  }

  get endDate() {
    return ShortlandController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    ShortlandController.setStartDate(value[0]);
    ShortlandController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchShortlandList();
  }

  // filter search
  get searchValue() {
    return ShortlandController.filter.search;
  }

  onSearch(value: string) {
    ShortlandController.setSearch(value);
    this.fetchShortlandList();
  }

  clearSearch() {
    ShortlandController.setSearch("");
    this.fetchShortlandList();
  }

  // go to update sti-dest
  goToUpdate() {
    this.$router.push("/sti-dest/generate");
  }

  // Table
  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (_: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Kargo",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.cargoNo}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "Tipe Kargo",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.cargoType}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "No. STT Elexys",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.sttNoElexys}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: `No. STT ${addPrefixGenesis()}`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.sttNo}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: `No. STT Pieces ${addPrefixGenesis()}`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.sttNo}-${x.sttPiecesNo}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.arrivalCityCode} - ${x.arrivalCityName}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${ellipsisString(
              x.commodityName,
              43
            )}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "Koli",
        styleHead: "w-28 text-left",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.sttPiecesNo}/${x.sttTotalPiece}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "Gross Weight",
        styleHead: "w-32 text-left",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.grossWeight.toFixed(
              2
            )}</div>`;
          });
          html += `</div>`;
          return html;
        }
      },
      {
        name: "Volume Weight",
        styleHead: "w-36 text-left",
        render: (item: any) => {
          let html = `<div class="space-y-6">`;
          item.shortland.map((x: ShortlandData) => {
            html += `<div class="text-black-lp-300">${x.volumeWeight.toFixed(
              2
            )}</div>`;
          });
          html += `</div>`;
          return html;
        }
      }
    ];
    if (!isMigrateFromElexys) {
      columns.splice(3, 1);
    }
    return columns;
  }

  get pagination() {
    return ShortlandController.shortlandList.pagination;
  }

  get shortlandList() {
    const data: Array<any> = [];
    ShortlandController.shortlandList.data.forEach((e: ShortlandData) => {
      const indexCurrent = data.findIndex(item => item.cargoNo === e.cargoNo);
      if (data.length > 0 && indexCurrent > -1) {
        data[indexCurrent].shortland.push(e);
      } else
        data.push({
          cargoNo: e.cargoNo,
          shortland: [e]
        });
    });
    return data;
  }

  // download csv
  get fileName() {
    return `Shortland_${dateToDateString(
      this.startDate ? new Date(this.startDate) : new Date()
    )}_${dateToDateString(
      this.endDate ? new Date(this.endDate) : new Date()
    )}.csv`;
  }

  get mappingCsv() {
    const data: any = [];
    if (this.shortlandList.length > 0) {
      this.shortlandList.forEach(e => {
        e.shortland.forEach((item: ShortlandData, index: number) => {
          if (isMigrateFromElexys()) {
            data.push({
              No: index + 1,
              "No. Kargo": item.cargoNo,
              "Tipe Kargo": item.cargoType,
              "No. STT Elexys": item.sttNoElexys,
              "No. STT Genesis": item.sttNo,
              "No. STT Pieces Genesis": `${item.sttNo}-${item.sttPiecesNo}`,
              "Kota Kedatangan": item.arrivalCityName,
              Komoditas: item.commodityName,
              Koli: item.sttPiecesNo,
              "Gross Weight": `${item.grossWeight.toFixed(2)} Kg`,
              "Volume Weight": `${item.volumeWeight.toFixed(2)} Kg`
            });
          } else {
            data.push({
              No: index + 1,
              "No. Kargo": item.cargoNo,
              "Tipe Kargo": item.cargoType,
              "No. STT": item.sttNo,
              "No. STT Pieces": `${item.sttNo}-${item.sttPiecesNo}`,
              "Kota Kedatangan": item.arrivalCityName,
              Komoditas: item.commodityName,
              Koli: item.sttPiecesNo,
              "Gross Weight": `${item.grossWeight.toFixed(2)} Kg`,
              "Volume Weight": `${item.volumeWeight.toFixed(2)} Kg`
            });
          }
        });
      });
      return data;
    }
    return data;
  }

  get isDownloadDisabled() {
    return this.shortlandList.length === 0;
  }

  // Refresh notification
  isUpdate = false;
}
