import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { ShortlandEntities } from "@/domain/entities/Shortland";
import { container } from "tsyringe";
import { ShortlandPresenter } from "../presenters/ShortlandPresenter";

export interface ShortlandState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "incoming-shortland" })
class ShortlandStore extends VuexModule implements ShortlandState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public filter = {
    startDate: "",
    endDate: "",
    search: ""
  };
  public pagination = {
    page: 1,
    limit: 10
  };
  public shortlandList = new ShortlandEntities(new Pagination(1, 10), []);

  @Action
  getShortlandList(params: {
    page: number;
    limit: number;
    startDate: string;
    endDate: string;
    search: string;
  }) {
    this.setIsLoading(true);
    const presenter = container.resolve(ShortlandPresenter);
    return presenter
      .getShortlandList(
        params.page,
        params.limit,
        params.startDate,
        params.endDate,
        params.search
      )
      .then((res: ShortlandEntities) => {
        this.setShortlandListData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  @Action
  public fetchShortlandList() {
    this.getShortlandList({
      page: this.shortlandList.pagination.page,
      limit: this.shortlandList.pagination.limit,
      startDate: this.filter.startDate
        ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
        : "",
      endDate: this.filter.endDate
        ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
        : "",
      search: this.filter.search
    });
  }

  @Mutation
  private setShortlandListData(data: ShortlandEntities) {
    this.shortlandList = data;
  }

  @Mutation
  public setResetShortlandListData() {
    this.shortlandList = new ShortlandEntities(new Pagination(1, 10), []);
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }
}

export const ShortlandController = getModule(ShortlandStore);
